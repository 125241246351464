import { all, takeLatest, take, fork, call } from 'redux-saga/effects'
import * as A from 'domain/certifications'
export function* certificationsPage() {
  yield all([
    fork(getCertifications),
    fork(addCertification),
    fork(getCertification),
    fork(deleteCertification),
    fork(updateCertification)
  ])
}

function* getCertifications() {
  yield call(A.ensureCertificationsList)
  yield takeLatest(A.certificationsList.type, A.ensureCertificationsList)
  yield take(A.certificationsList.success)
}

function* addCertification() {
  yield takeLatest(A.addCertification.TRIGGER, A.ensureAddCertification)
  yield take(A.addCertification.SUCCESS)
}

function* getCertification() {
  yield takeLatest(A.certification.type, A.ensureCertification)
  yield take(A.certification.success)
}

function* deleteCertification() {
  yield takeLatest(A.removeCertification.TRIGGER, A.ensureRemoveCertification)
  yield take(A.removeCertification.SUCCESS)
}

function* updateCertification() {
  yield takeLatest(A.updateCertification.TRIGGER, A.ensureUpdateCertification)
  yield take(A.updateCertification.SUCCESS)
}
