import { DataForSend, Rate, PropsProfilePayload } from './types'
import { normalizePhone } from 'lib/normalize'
import { Certification, ImmutableMap } from 'types/common'
import { InfoType } from 'pages/Recruiter/Candidates/AddEditProfile/types'
import { calcDateYearsAgo } from '../../../lib/helpers'

const API_URL = process.env.REACT_APP_API_PATH_DOWNLOADS

export const transformDataForRequest = ({
  data,
  educations,
  certifications,
  workExperiences,
  resume
}: PropsProfilePayload) => {
  const dataForSend: DataForSend = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    countryId: data.country ? data.country.value : undefined,
    cityId: data.city ? data.city.value : undefined,
    phonePrefix: data.phonePrefix ? data.phonePrefix.label : undefined,
    phone: data.phone ? data.phone.split(' ').join('') : undefined
  }
  if (data.birthday) {
    dataForSend.birthday = data.birthday
  }
  const hourly: Rate = {}
  const salary: Rate = {}
  const hourlyRateFrom = data && data.hourly && data.hourly.rateFrom && Number(data.hourly.rateFrom)
  const hourlyRateTo = data && data.hourly && data.hourly.rateTo && Number(data.hourly.rateTo)
  const salaryRateFrom = data && data.salary && data.salary.rateFrom && Number(data.salary.rateFrom)
  const salaryRateTo = data && data.salary && data.salary.rateTo && Number(data.salary.rateTo)
  if (hourlyRateFrom) hourly.rateFrom = hourlyRateFrom
  if (hourlyRateTo) hourly.rateTo = hourlyRateTo
  if (salaryRateFrom) salary.rateFrom = salaryRateFrom
  if (salaryRateTo) salary.rateTo = salaryRateTo
  if (hourly.rateFrom || hourly.rateTo) {
    hourly.currency = data && data.hourly && data.hourly.currency && data.hourly.currency.label
  }
  if (salary.rateFrom || salary.rateTo) {
    salary.currency = data && data.salary && data.salary.currency && data.salary.currency.label
  }
  const clearanceType = data.clearanceType || {}
  const citizenship = data.citizenship || {}
  const workStartedAt = (data && calcDateYearsAgo(data.workYears)) || undefined

  const result: { [x: string]: any } = {
    ...dataForSend,
    resume: resume || undefined,
    parsedResumePath: data.parsedResumePath || undefined,
    employeeInfo: {
      skills: data.skills && data.skills.length ? data.skills : undefined,
      relocation: data.relocation === 'true',
      softSkills: data.softSkills || undefined,
      hourly: Object.keys(hourly).length > 0 ? hourly : undefined,
      salary: Object.keys(salary).length > 0 ? salary : undefined,
      citizenship: JSON.stringify(
        Object.keys(citizenship).filter(
          (key: string) => !!citizenship[key] && key !== 'citizenshipDesc'
        )
      ),
      citizenshipDesc: (data.citizenship && data.citizenship.citizenshipDesc) || undefined,
      workStartedAt
    },
    educations: JSON.stringify(
      educations.map(item => ({
        id: item.id,
        school: item.school,
        degree: item.degree,
        study: item.study,
        from: Number(item.from),
        to: !item.to || item.to < 0 ? null : Number(item.to)
      }))
    ),
    certifications: JSON.stringify(
      certifications.map((item: Certification) => ({
        id: item.id,
        name: item.name,
        issueOrg: item.issueOrg,
        issueMonth: item.issueMonth,
        issueYear: item.issueYear,
        expireMonth: item.expireMonth,
        expireYear: item.expireYear,
        credentialId: item.credentialId,
        credentialUrl: item.credentialUrl
      }))
    ),
    clearanceType: JSON.stringify(
      Object.keys(clearanceType).filter((key: string) => !!clearanceType[key] && key !== 'none')
    ),
    workExperiences: JSON.stringify(
      workExperiences.map(item => ({
        id: item.id,
        title: item.title,
        company: item.company,
        location: item.location,
        isWorking: Boolean(item.isWorking),
        fromYear: Number(item.fromYear),
        fromMonth: Number(item.fromMonth),
        toYear: item.toYear && item.toYear > 0 ? Number(item.toYear) : null,
        toMonth: item.toMonth ? Number(item.toMonth) : null
      }))
    )
  }

  const resultFormData = new FormData()
  buildFormData(resultFormData, result)
  return resultFormData
}

export const transformCandidate = (data: ImmutableMap<InfoType>) => {
  return data
    .update('avatar', (val: string) => (val ? `${API_URL}/${val}` : undefined))
    .update('phone', normalizePhone(data.get('phonePrefix')))
}

function buildFormData(formData: FormData, data: any, parentKey?: string) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    if (data != null && parentKey) {
      formData.append(parentKey, data)
    }
  }
}

export function buildResumeFormData(payload: any) {
  const result = new FormData()
  result.append('resume', payload.data)
  result.append('hash', payload.hash)

  return result
}
