import { all, takeLatest, take, fork, call, put } from 'redux-saga/effects'
import { ensureAddAvatar } from 'domain/avatar/sagas'
import { educationsPage } from 'pages/Profile/Education/sagas'
import { certificationsPage } from 'pages/Profile/Certification/sagas'
import { pointsPage } from 'pages/Profile/Points/sagas'
import { pageIsLoading } from 'domain/loading'
import * as A from 'domain/profile'
import * as L from 'domain/locations'
import * as Avatar from 'domain/avatar/actions'
import * as R from 'domain/manager/recruiters'
import * as RP from 'domain/recruiter/profile'
import * as InviteRecruiter from 'domain/manager/anotherManagersRecruiter'
import { checkAuth } from 'domain/env/sagas'

export function* profilePage() {
  yield all([
    fork(educationsPage),
    fork(certificationsPage),
    fork(addAvatar),
    fork(updateProfile),
    fork(getHM),
    fork(pointsPage),
    fork(initialLoad),
    fork(changePassword),
    fork(acceptOtherRecruiter),
    fork(declineOtherRecruiter)
  ])
}

function* changePassword() {
  yield takeLatest(A.changePassword.TRIGGER, A.ensureChangePassword)
}

function* addAvatar() {
  yield takeLatest(Avatar.addAvatar.type, ensureAddAvatar)
  yield take(Avatar.addAvatar.success)
}

function* updateProfile() {
  yield takeLatest(A.updateProfile.TRIGGER, A.ensureUpdateProfile)
  yield take(A.updateProfile.SUCCESS)
}

function* getHM() {
  yield takeLatest(R.recruitersList.type, RP.ensureHMList)
  yield take(R.recruitersList.success)
}

function* acceptOtherRecruiter() {
  yield takeLatest(
    InviteRecruiter.acceptOtherRecruiter.type,
    InviteRecruiter.ensureAcceptOtherRecruiter
  )
  yield take(InviteRecruiter.acceptOtherRecruiter.success)
  yield call(RP.ensureHMList)
  yield call(checkAuth)
}

function* declineOtherRecruiter() {
  yield takeLatest(
    InviteRecruiter.declineOtherRecruiter.type,
    InviteRecruiter.ensureDeclineOtherRecruiter
  )
  yield take(InviteRecruiter.declineOtherRecruiter.success)
  yield call(RP.ensureHMList)
  yield call(checkAuth)
}

function* initialLoad() {
  try {
    yield put({ type: pageIsLoading, payload: true })
    yield all([call(RP.ensureHMList), call(L.ensureGetCounties)])
    yield put({ type: 'profile/CLEAR_CITIES' })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
