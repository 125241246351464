import { CertificationInterface } from 'pages/Recruiter/Candidates/AddEditProfile/types'

function normalizeDateField(
  field: { value: string | number; label: string | number } | null | undefined
): number | null {
  if (!field || field.value === '' || isNaN(Number(field.value))) return null

  const num = Number(field.value)
  return num >= 0 ? num : null
}

export const transformData = (data: CertificationInterface) => {
  return {
    name: data.name,
    issueOrg: data.issueOrg,
    issueMonth: normalizeDateField(data.issueMonth),
    issueYear: normalizeDateField(data.issueYear),
    expireMonth: normalizeDateField(data.expireMonth),
    expireYear: normalizeDateField(data.expireYear),
    credentialId: data.credentialId,
    credentialUrl: data.credentialUrl
  }
}
