import I from 'immutable'
import { put, call, select } from 'redux-saga/effects'
import { isLoading, modalIsLoading, pageIsLoading } from 'domain/loading'
import * as Actions from './actions'
import * as M from 'domain/env'
import Api from 'domain/api'
import { transformData } from './helpers'
import { CertificationInterface } from 'pages/Recruiter/Candidates/AddEditProfile/types'

export function* ensureCertificationsList() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getCertificationsList, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({
      type: Actions.certificationsList.success,
      payload: I.fromJS(data)
    })
  } catch (err) {
    yield put({
      type: Actions.certificationsList.failure,
      err
    })
  }
}

export function* ensureAddCertification({
  payload
}: {
  payload: CertificationInterface
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.postCertifications, {
      headers: { Authorization: `Bearer ${headers}` },
      data: transformData(payload)
    })
    yield put({ type: Actions.addCertification.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.certificationsList.type })
  } catch (err) {
    yield put({
      type: Actions.addCertification.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureCertification({ payload }: { payload: number; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  yield put({ type: isLoading, payload: true })
  yield put({ type: pageIsLoading, payload: true })
  try {
    const {
      data: { data }
    } = yield call(Api.getCertification, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload
    })
    yield put({ type: Actions.certification.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.certification.failure,
      err
    })
  } finally {
    yield put({ type: isLoading, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureUpdateCertification({
  payload
}: {
  payload: CertificationInterface
  type: string
}) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.putCertification, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload.id,
      data: transformData(payload)
    })
    yield put({ type: Actions.updateCertification.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.certificationsList.type })
  } catch (err) {
    yield put({
      type: Actions.updateCertification.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureRemoveCertification({ payload }: { payload: number; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data }
    } = yield call(Api.deleteCertification, {
      headers: { Authorization: `Bearer ${headers}` },
      id: payload
    })
    yield put({ type: Actions.removeCertification.SUCCESS, payload: I.fromJS(data) })
    yield put({ type: Actions.certificationsList.type })
  } catch (err) {
    yield put({
      type: Actions.removeCertification.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}
