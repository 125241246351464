import { all, fork, takeLatest, take, put, call, select } from 'redux-saga/effects'
import { clearStorage } from 'lib/storage'
import { push } from 'connected-react-router'
import { query } from 'domain/router'
import { connect } from 'sagas/socketSaga'
import { fetchNotifications } from 'pages/Notifications/sagas'
import { ServerRoles } from 'lib/roles'
import { inviteRecruiters } from 'pages/Manager/InviteRecruiters/sagas'
import { inviteCandidates } from 'pages/Candidate/InviteCandidates/sagas'
import { pointsRewards } from 'pages/Profile/Points/sagas'
import {
  ensureSignUpCandidate,
  ensureSignIn,
  ensureSignUpManager,
  checkAuth,
  checkConfirmation,
  ensureRecoveryPassword,
  ensureUpdatePassword,
  recruiterConfirmation,
  acceptRecommendationJob,
  ensureSignUpRecruiter
} from 'domain/env/sagas'
import { linkForCheck } from 'lib/beforeLogOut'
import { NotificationsListType } from 'pages/Notifications/types'
import * as Env from 'domain/env'
import * as C from 'domain/manager/clients'
import * as Router from 'domain/router'
import * as Notification from 'domain/notifications'
import * as CandidateJobs from 'domain/candidate/jobs'
import * as RecruiterJobs from 'domain/recruiter/jobs'

function* watchSignUpCandidate() {
  yield takeLatest(Env.signUpCandidate.type, ensureSignUpCandidate)
  yield take(Env.signUpCandidate.success)
  yield put(push('/account/check-confirmation'))
}

function* watchSignUpManager() {
  yield takeLatest(Env.signUpManager.type, ensureSignUpManager)
  yield take(Env.signUpManager.success)
  yield put(push('/account/check-confirmation'))
}

function* watchSignUpRecruiter() {
  yield takeLatest(Env.signUpRecruiter.type, ensureSignUpRecruiter)
  yield take(Env.signUpRecruiter.success)
  yield put(push('/account/check-confirmation'))
}

function* watchRecoveryPassword() {
  yield takeLatest(Env.recoveryPassword.type, ensureRecoveryPassword)
  yield take(Env.recoveryPassword.success)
  yield put(push('/account/check-confirmation'))
}

function* watchSignIn() {
  yield takeLatest(Env.signIn.type, ensureSignIn)
}

export function* signInPage() {
  yield all([fork(watchSignIn)])
}
export function* signUpPage() {
  yield all([fork(watchSignUpCandidate), fork(watchSignUpRecruiter), fork(watchSignUpManager)])
}
export function* recoveryPasswordPage() {
  yield all([fork(watchRecoveryPassword)])
}

export function* candidateInvite() {
  const queryParams = yield select(query)

  if (queryParams && queryParams.token && queryParams.email) {
    yield all([fork(watchSignUpCandidate)])
  } else {
    yield put(push('/sign-up'))
  }
}

export function* ensureSign() {
  yield call(checkAuth)
}

export function* ensureSignOut() {
  yield takeLatest(Env.signOut.type, signOut)
}

export function* signOut(action: any) {
  try {
    const { pathname } = yield select(Router.location)
    if (pathname === linkForCheck.notifications) {
      const notifications: NotificationsListType = yield select(Notification.notificationsSelector)
      const indexArray =
        notifications &&
        !notifications.isEmpty() &&
        notifications
          .filter(item => !item.get('viewedAt'))
          .map(item => item.get('id'))
          .toJS()
      if (indexArray && indexArray.length) {
        yield call(Notification.ensurePostNotificationsAsViewed, { payload: indexArray })
      }
    }

    const skipStorageClear = action && action.payload && action.payload.skipStorageClear
    if (!skipStorageClear) {
      clearStorage()
    }
    if (pathname !== '/') {
      yield put({ type: Env.signOut.success })
    }
  } catch (e) {
    yield put({ type: Env.signOut.success })
  }
}

export function* confirmationToken() {
  yield call(checkConfirmation)
}

export function* updatePassword() {
  const { token } = yield select(query)
  if (token) {
    yield takeLatest(Env.updatePassword.type, ensureUpdatePassword)
    yield take(Env.updatePassword.success)
    yield put(push('/log-in'))
  } else {
    yield put(push('/'))
  }
}

export function* recruiterAccept() {
  const { token } = yield select(query)
  if (token) {
    yield takeLatest(Env.recruiterAccept.type, recruiterConfirmation)
    yield take(Env.recruiterAccept.success)
    yield put(push('/log-in'))
  } else {
    yield put(push('/'))
  }
}

export function* recommendJobAccept() {
  const { token, email } = yield select(query)
  if (token && email) {
    yield takeLatest(Env.acceptRecommendJob.type, acceptRecommendationJob)
    yield take(Env.acceptRecommendJob.success)
    yield put(push('/log-in'))
  } else {
    yield put(push('/'))
  }
}

export function* rootAfterSignIn() {
  yield fork(connect)
  yield fork(fetchNotifications)
  const env = yield select(Env.userSelector)
  if (env && !env.isEmpty() && env.get('role') === ServerRoles.manager) {
    yield fork(inviteRecruiters)
    yield fork(addClient)
  }
  if (env && !env.isEmpty() && env.get('role') === ServerRoles.recruiter) {
    yield fork(pointsRewards)
    yield fork(RecruiterJobs.ensureGetJobsCount)
  }
  if (env && !env.isEmpty() && env.get('role') === ServerRoles.candidate) {
    yield fork(inviteCandidates)
    yield fork(CandidateJobs.ensureGetJobsCount)
  }
}

function* addClient() {
  yield takeLatest(C.postClient.TRIGGER, C.ensurePostClient)
  yield take(C.postClient.SUCCESS)
}
