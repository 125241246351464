import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const certificationsList = asyncAction('CERTIFICATIONS_LIST')
export const certification = asyncAction('CERTIFICATION')

export const updateCertification = createRoutine('UPDATE_CERTIFICATION')
export const removeCertification = createRoutine('REMOVE_CERTIFICATION')
export const addCertification = createRoutine('ADD_CERTIFICATION')
export const updateCertificationPromiseCreator = promisifyRoutine(updateCertification)
export const removeCertificationPromiseCreator = promisifyRoutine(removeCertification)
export const addCertificationPromiseCreator = promisifyRoutine(addCertification)
