import { all, takeLatest, take, fork, call, put } from 'redux-saga/effects'
import { educationsPage } from 'pages/Profile/Education/sagas'
import { certificationsPage } from 'pages/Profile/Certification/sagas'
import { experiencesPage } from 'pages/Profile/WorkExperience/sagas'
import { pointsPage } from 'pages/Profile/Points/sagas'
import { pageIsLoading } from 'domain/loading'
import * as P from 'domain/profile'
import * as L from 'domain/locations'
import * as R from 'domain/resume'
import * as A from 'domain/avatar/actions'
import * as C from 'domain/candidate/profile'
import * as ASagas from 'domain/avatar/sagas'
import * as Candidates from 'domain/candidate/candidates'

export function* profilePage() {
  yield all([
    fork(educationsPage),
    fork(certificationsPage),
    fork(experiencesPage),
    fork(addAvatar),
    fork(getResume),
    fork(addResume),
    fork(pointsPage),
    fork(deleteResume),
    fork(updateProfile),
    fork(getEmployeeInfo),
    fork(addEmployeeInfo),
    fork(initialLoad),
    fork(changePassword)
  ])
}

function* changePassword() {
  yield takeLatest(P.changePassword.TRIGGER, P.ensureChangePassword)
}

function* addAvatar() {
  yield takeLatest(A.addAvatar.type, ASagas.ensureAddAvatar)
  yield take(A.addAvatar.success)
}

// resume
function* getResume() {
  yield takeLatest(R.resume.type, R.ensureResume)
  yield take(R.resume.success)
}

function* addResume() {
  yield takeLatest(R.addResume.type, R.ensureAddResume)
  yield take(R.addResume.success)
}

function* deleteResume() {
  yield takeLatest(R.removeResume.type, R.ensureRemoveResume)
  yield take(R.removeResume.success)
}

function* updateProfile() {
  yield takeLatest(P.updateProfile.TRIGGER, P.ensureUpdateProfile)
  yield take(P.updateProfile.SUCCESS)
}

function* getEmployeeInfo() {
  yield takeLatest(C.employeeInfo.type, C.ensureEmployeeInfo)
  yield take(C.employeeInfo.success)
}

function* addEmployeeInfo() {
  yield takeLatest(C.addEmployeeInfo.TRIGGER, C.ensureAddEmployeeInfo)
  yield take(C.addEmployeeInfo.SUCCESS)
}

function* initialLoad() {
  yield put({ type: pageIsLoading, payload: true })
  try {
    yield all([call(L.ensureGetCounties), call(R.ensureResume), call(C.ensureEmployeeInfo)])
    yield put({ type: 'profile/CLEAR_CITIES' })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
