import { EmployeeInfoType } from 'pages/Profile/EmployeeInfo/types'
import { calcDateYearsAgo } from '../../../lib/helpers'

interface Rate {
  rateFrom?: number
  rateTo?: number
  currency?: string
}

export const transformData = (data: EmployeeInfoType) => {
  const hourly: Rate = {}
  const salary: Rate = {}
  const hourlyRateFrom = data && data.hourly && data.hourly.rateFrom && Number(data.hourly.rateFrom)
  const hourlyRateTo = data && data.hourly && data.hourly.rateTo && Number(data.hourly.rateTo)
  const salaryRateFrom = data && data.salary && data.salary.rateFrom && Number(data.salary.rateFrom)
  const salaryRateTo = data && data.salary && data.salary.rateTo && Number(data.salary.rateTo)
  if (hourlyRateFrom) hourly.rateFrom = hourlyRateFrom
  if (hourlyRateTo) hourly.rateTo = hourlyRateTo
  if (salaryRateFrom) salary.rateFrom = salaryRateFrom
  if (salaryRateTo) salary.rateTo = salaryRateTo
  if (hourly.rateFrom || hourly.rateTo) {
    hourly.currency = data && data.hourly && data.hourly.currency.label
  }
  if (salary.rateFrom || salary.rateTo) {
    salary.currency = data && data.salary && data.salary.currency.label
  }
  const citizenship = data.citizenship || {}
  const workStartedAt = (data && calcDateYearsAgo(data.workYears)) || undefined

  return {
    ...data,
    relocation: data.relocation === 'true',
    hourly: Object.keys(hourly).length > 0 ? hourly : undefined,
    salary: Object.keys(salary).length > 0 ? salary : undefined,
    citizenship: JSON.stringify(
      Object.keys(citizenship).filter(
        (key: string) => !!citizenship[key] && key !== 'citizenshipDesc'
      )
    ),
    citizenshipDesc: (citizenship && citizenship.citizenshipDesc) || undefined,
    workYears: undefined,
    workStartedAt
  }
}
