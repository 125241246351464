import { Field } from 'redux-form'
import { PropInjector } from 'react-jss'
import { Classes } from 'jss'

export interface InputPropsType extends PropInjector<any> {
  type: string
  name: string
  title?: string
  isPassword?: boolean
  placeholder?: string
  component?: any
  readOnly?: boolean
  onChange?: (arg: string) => void
  borderColor?: BorderColor
  education?: boolean
  certification?: boolean
  experience?: boolean
  disabled?: boolean
  autoComplete?: string
  isLoading?: boolean
  withCheckIcon?: boolean
  customLayoutClasses?: Classes | string
}

export enum BorderColor {
  Success = 'success',
  ErrorEmail = 'error'
}

export const FieldInput = Field as new () => Field<InputPropsType>
