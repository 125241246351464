import axios, { AxiosPromise } from 'axios'
import { history } from 'lib/history'

const API_URL = process.env.REACT_APP_API_PATH

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error && 401 === error.response.status) {
      history.push('/')
    } else {
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

type makeUrlType = (args?: object) => AxiosPromise

function methodUrl(method: string, url: any): makeUrlType {
  return args =>
    axios({
      method,
      url: typeof url === 'function' ? url(args) : url,
      ...args
    })
}

export default {
  // auth
  getConstants: methodUrl('get', `${API_URL}/api/constants`),
  verifyToken: methodUrl('get', `${API_URL}/api/auth/verify`),
  checkAuth: methodUrl('get', `${API_URL}/api/profile`),
  signUpManager: methodUrl('post', `${API_URL}/api/auth/sign-up/manager`),
  signUpRecruiter: methodUrl('post', `${API_URL}/api/auth/sign-up/recruiter`),
  signUpCandidate: methodUrl('post', `${API_URL}/api/auth/sign-up/candidate`),
  signIn: methodUrl('post', `${API_URL}/api/auth/sign-in`),
  confirmation: methodUrl('post', `${API_URL}/api/auth/confirmation`),
  recoveryPassword: methodUrl('post', `${API_URL}/api/auth/password/recovery`),
  updatePassword: methodUrl('post', `${API_URL}/api/auth/password/update`),
  addAvatar: methodUrl('post', `${API_URL}/api/profile/avatar`),
  recruiterAcceptInvitation: methodUrl('post', `${API_URL}/api/recruiter/accept-invitation`),
  signUpInvitedCandidate: methodUrl(
    'post',
    ({ search }: { search: string }) => `${API_URL}/api/auth/sign-up/candidate${search}`
  ),

  getJobsGuest: methodUrl('get', `${API_URL}/api/guest/jobs`),
  getCampaignsGuest: methodUrl('get', `${API_URL}/api/guest/campaigns`),

  // profile
  getCountries: methodUrl('get', `${API_URL}/api/location/countries`),
  getCities: methodUrl('get', `${API_URL}/api/location/cities`),
  getCity: methodUrl(
    'get',
    ({ cityId }: { cityId: number }) => `${API_URL}/api/location/cities/${cityId}`
  ),
  profile: methodUrl('put', `${API_URL}/api/profile`),
  changePasswordProfile: methodUrl('patch', `${API_URL}/api/profile/password`),
  getTags: methodUrl('get', `${API_URL}/api/tags`),

  // chat
  registerSbUser: methodUrl('post', ({ id }: { id: number }) => `${API_URL}/api/chat/users/${id}`),
  updateSbUser: methodUrl('put', ({ id }: { id: number }) => `${API_URL}/api/chat/users/${id}`),

  // manager
  inviteRecruiters: methodUrl('post', `${API_URL}/api/manager/recruiters/invite`),
  socialLinks: methodUrl('put', `${API_URL}/api/manager/profile/social-links`),
  getRecruiters: methodUrl('get', `${API_URL}/api/manager/profile/recruiters`),
  getAllRecruiters: methodUrl('get', `${API_URL}/api/manager/recruiters`),
  getInvitedRecruiters: methodUrl('get', `${API_URL}/api/manager/recruiters/invited`),
  deleteRecruiter: methodUrl(
    'delete',
    ({ id }: { id: number }) => `${API_URL}/api/manager/recruiters/${id}`
  ),
  declineInvitationToRecruiter: methodUrl(
    'delete',
    ({ id }: { id: number }) => `${API_URL}/api/manager/recruiters/invitations/${id}`
  ),
  resendInvitationToRecruiter: methodUrl(
    'put',
    ({ id }: { id: number }) => `${API_URL}/api/manager/recruiters/invitations/${id}`
  ),
  candidateAsViewedM: methodUrl(
    'post',
    ({ id }: { id: number }) => `${API_URL}/api/manager/candidates/${id}/viewed`
  ),
  getRecruiterProfile: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/manager/recruiters/${id}`
  ),
  getManagerProfile: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/manager/managers/${id}`
  ),
  getCandidateProfileM: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/manager/candidates/${id}`
  ),
  getCandidatesM: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/manager/jobs/${id}/candidates`
  ),
  getJobsForCandidateCloseJobM: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/manager/candidates/${id}/apply/jobs`
  ),
  getRecruitersForCandidateAndJobCloseJobM: methodUrl(
    'get',
    ({ id, jobId }: { id: number; jobId: number }) =>
      `${API_URL}/api/manager/candidates/${id}/apply/jobs/${jobId}/recruiters`
  ),
  applyCandidateM: methodUrl(
    'post',
    ({ id }: { id: number }) => `${API_URL}/api/manager/candidates/${id}/apply`
  ),
  declineCandidateM: methodUrl(
    'post',
    ({ id }: { id: number }) => `${API_URL}/api/manager/candidates/${id}/decline`
  ),
  managerCollaborationRequests: methodUrl('get', `${API_URL}/api/user/collaboration-requests`),
  rejectManagerCollaborationRequest: methodUrl(
    'delete',
    ({ id }: { id: number }) => `${API_URL}/api/user/collaboration-requests/${id}/reject`
  ),
  acceptManagerCollaborationRequest: methodUrl(
    'patch',
    ({ id }: { id: number }) => `${API_URL}/api/user/collaboration-requests/${id}/accept`
  ),

  // manager invite other recruiters
  getActionsList: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/managers/recruiters/${id}/actions-list`
  ),
  inviteOtherRecruiter: methodUrl(
    'post',
    ({ recruiterId }: { recruiterId: number }) =>
      `${API_URL}/api/managers/recruiters/${recruiterId}/invite`
  ),
  declineInviteRecruiter: methodUrl(
    'post',
    ({ managerId, recruiterId }: { managerId: number; recruiterId: number }) =>
      `${API_URL}/api/managers/${managerId}/recruiters/${recruiterId}/decline`
  ),
  acceptInviteRecruiter: methodUrl(
    'post',
    ({ managerId, recruiterId }: { managerId: number; recruiterId: number }) =>
      `${API_URL}/api/managers/${managerId}/recruiters/${recruiterId}/accept`
  ),
  closeInviteRecruiter: methodUrl(
    'post',
    ({ managerId, recruiterId }: { managerId: number; recruiterId: number }) =>
      `${API_URL}/api/managers/${managerId}/recruiters/${recruiterId}/close`
  ),

  // Associated Skills
  getAssocSkillData: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/manager/associated-tags/${id}`
  ),
  getAssocSkillsList: methodUrl('get', `${API_URL}/api/manager/associated-tags`),
  postAssocSkillData: methodUrl('post', `${API_URL}/api/manager/associated-tags`),
  deleteAssocSkillItem: methodUrl(
    'delete',
    ({ tagId, associatedTagId }: { tagId: number; associatedTagId: number }) =>
      `${API_URL}/api/manager/associated-tags/${tagId}/${associatedTagId}`
  ),

  // manager jobs
  getDomainsM: methodUrl('get', `${API_URL}/api/manager/campaigns/get-domains`),
  getCampaignsM: methodUrl('get', `${API_URL}/api/manager/campaigns`),
  getCampaignDataM: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/manager/campaigns/${id}`
  ),
  checkCampaignNameM: methodUrl('get', `${API_URL}/api/manager/campaigns/check`),
  postCampaignM: methodUrl('post', `${API_URL}/api/manager/campaigns`),
  putCampaignM: methodUrl(
    'put',
    ({ id }: { id: number }) => `${API_URL}/api/manager/campaigns/${id}`
  ),
  getAllJobs: methodUrl('get', `${API_URL}/api/manager/jobs/all`),
  getJobs: methodUrl('get', `${API_URL}/api/manager/jobs`),
  extractSkills: methodUrl('post', `${API_URL}/api/manager/jobs/extract-skills`),
  postJob: methodUrl('post', `${API_URL}/api/manager/jobs`),
  getJob: methodUrl('get', ({ id }: { id: number }) => `${API_URL}/api/manager/jobs/${id}`),
  putJob: methodUrl('put', ({ id }: { id: number }) => `${API_URL}/api/manager/jobs/${id}`),
  deleteJob: methodUrl('delete', ({ id }: { id: number }) => `${API_URL}/api/manager/jobs/${id}`),
  toggleHoldJob: methodUrl(
    'post',
    ({ id }: { id: number }) => `${API_URL}/api/manager/jobs/${id}/toggle-hold`
  ),
  closeJob: methodUrl(
    'post',
    ({ id }: { id: number }) => `${API_URL}/api/manager/jobs/${id}/close`
  ),
  candidatesForCloseJob: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/manager/jobs/${id}/close/candidates`
  ),
  recruitersForCloseJob: methodUrl(
    'get',
    ({ id, candidateId }: { id: number; candidateId: number }) =>
      `${API_URL}/api/manager/jobs/${id}/close/candidates/${candidateId}/recruiters`
  ),
  getManagersList: methodUrl('get', `${API_URL}/api/manager/jobs/managers-list`),

  // manager clients
  getClientsListForJob: methodUrl('get', `${API_URL}/api/manager/clients/info-list`),
  getClientsList: methodUrl('get', `${API_URL}/api/manager/clients`),
  postClient: methodUrl('post', `${API_URL}/api/manager/clients`),
  getClient: methodUrl('get', ({ id }: { id: number }) => `${API_URL}/api/manager/clients/${id}`),
  putClient: methodUrl('put', ({ id }: { id: number }) => `${API_URL}/api/manager/clients/${id}`),
  deleteClient: methodUrl(
    'delete',
    ({ id }: { id: number }) => `${API_URL}/api/manager/clients/${id}`
  ),

  // manager dashboard
  managerJobsCounters: methodUrl('get', `${API_URL}/api/manager/dashboard/jobs-counters`),
  managerClientsCounters: methodUrl('get', `${API_URL}/api/manager/dashboard/clients-counters`),
  managerClientClosureRate: methodUrl(
    'get',
    `${API_URL}/api/manager/dashboard/clients-closure-rate`
  ),
  managerStatistics: methodUrl('get', `${API_URL}/api/manager/dashboard/statistics`),
  managerTopRecruiters: methodUrl('get', `${API_URL}/api/manager/dashboard/top-recruiters`),
  managerClosedJobs: methodUrl('get', `${API_URL}/api/manager/dashboard/closed-jobs`),

  // manager calendar
  manager: {
    getEvent: methodUrl(
      'get',
      ({ id }: { id: number }) => `${API_URL}/api/manager/calendar/events/${id}`
    ),
    getEventsList: methodUrl('get', `${API_URL}/api/manager/calendar/events`),
    postEvent: methodUrl('post', `${API_URL}/api/manager/calendar/events`),
    putEvent: methodUrl(
      'put',
      ({ id }: { id: number }) => `${API_URL}/api/manager/calendar/events/${id}`
    ),
    deleteEvent: methodUrl(
      'delete',
      ({ id }: { id: number }) => `${API_URL}/api/manager/calendar/events/${id}`
    ),
    // manager calendar unavailability
    getEventUnavailability: methodUrl(
      'get',
      ({ id }: { id: number }) => `${API_URL}/api/manager/calendar/unavailability/${id}`
    ),
    getEventsListUnavailability: methodUrl('get', `${API_URL}/api/manager/calendar/unavailability`),
    postEventUnavailability: methodUrl('post', `${API_URL}/api/manager/calendar/unavailability`),
    putEventUnavailability: methodUrl(
      'put',
      ({ id }: { id: number }) => `${API_URL}/api/manager/calendar/unavailability/${id}`
    ),
    deleteEventUnavailability: methodUrl(
      'delete',
      ({ id }: { id: number }) => `${API_URL}/api/manager/calendar/unavailability/${id}`
    ),
    getCandidatesList: methodUrl('get', `${API_URL}/api/manager/candidates/list`)
  },

  // candidate
  getEmployeeInfo: methodUrl('get', `${API_URL}/api/candidate/employee-info`),
  postEmployeeInfo: methodUrl('post', `${API_URL}/api/candidate/employee-info`),
  postStatus: methodUrl(
    'post',
    ({ candidateId, jobId }: { candidateId: string; jobId: string }) =>
      `${API_URL}/api/candidate-job-status/${candidateId}/jobs/${jobId}`
  ),
  getStatus: methodUrl(
    'get',
    ({ candidateId, jobId }: { candidateId: string; jobId: string }) =>
      `${API_URL}/api/candidate-job-status/${candidateId}/jobs/${jobId}`
  ),

  // resume
  getResume: methodUrl('get', `${API_URL}/api/candidate/resume`),
  postResume: methodUrl('post', `${API_URL}/api/candidate/resume`),
  deleteResume: methodUrl('delete', `${API_URL}/api/candidate/resume`),

  // education
  getEducationsList: methodUrl('get', `${API_URL}/api/profile/educations`),
  postEducations: methodUrl('post', `${API_URL}/api/profile/educations`),
  getEducation: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/profile/educations/${id}`
  ),
  putEducation: methodUrl(
    'put',
    ({ id }: { id: number }) => `${API_URL}/api/profile/educations/${id}`
  ),
  deleteEducation: methodUrl(
    'delete',
    ({ id }: { id: number }) => `${API_URL}/api/profile/educations/${id}`
  ),

  // certification
  getCertificationsList: methodUrl('get', `${API_URL}/api/profile/certifications`),
  postCertifications: methodUrl('post', `${API_URL}/api/profile/certifications`),
  getCertification: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/profile/certifications/${id}`
  ),
  putCertification: methodUrl(
    'put',
    ({ id }: { id: number }) => `${API_URL}/api/profile/certifications/${id}`
  ),
  deleteCertification: methodUrl(
    'delete',
    ({ id }: { id: number }) => `${API_URL}/api/profile/certifications/${id}`
  ),

  // work experiences
  getExperiencesList: methodUrl('get', `${API_URL}/api/candidate/work-experiences`),
  postExperiences: methodUrl('post', `${API_URL}/api/candidate/work-experiences`),
  getExperiences: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/candidate/work-experiences/${id}`
  ),
  putExperiences: methodUrl(
    'put',
    ({ id }: { id: number }) => `${API_URL}/api/candidate/work-experiences/${id}`
  ),
  deleteExperiences: methodUrl(
    'delete',
    ({ id }: { id: number }) => `${API_URL}/api/candidate/work-experiences/${id}`
  ),

  // jobs
  getJobsCandidate: methodUrl('get', `${API_URL}/api/candidate/jobs`),
  getJobsCandidateCount: methodUrl('get', `${API_URL}/api/candidate/jobs/count/new`),
  getJobCandidate: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/candidate/jobs/${id}`
  ),
  assignJobCandidate: methodUrl(
    'post',
    ({ id }: { id: number }) => `${API_URL}/api/candidate/jobs/${id}/assign`
  ),
  acceptJobCandidate: methodUrl(
    'post',
    ({ id }: { id: number }) => `${API_URL}/api/candidate/jobs/${id}/accept`
  ),
  cancelJobCandidate: methodUrl(
    'post',
    ({ id }: { id: number }) => `${API_URL}/api/candidate/jobs/${id}/cancel`
  ),
  addJobToFavoriteCandidate: methodUrl(
    'post',
    ({ type, id }: { id: number; type: string }) => `${API_URL}/api/favorite/${type}/${id}`
  ),
  recommendJob: methodUrl('post', `${API_URL}/api/candidate/recommendations/create`),
  acceptRecommendJob: methodUrl('post', `${API_URL}/api/candidate/recommendations/accept`),
  getRecruiterProfileC: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/candidate/recruiters/${id}`
  ),
  getManagerProfileC: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/candidate/managers/${id}`
  ),
  getCandidateProfileC: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/candidate/candidates/${id}`
  ),

  // job notes
  getJobNotes: methodUrl('get', ({ id }: { id: number }) => `${API_URL}/api/job-notes/${id}`),
  postJobNotes: methodUrl('post', ({ id }: { id: number }) => `${API_URL}/api/job-notes/${id}`),
  putJobNotes: methodUrl(
    'put',
    ({ jobId, noteId }: { jobId: number; noteId: number }) =>
      `${API_URL}/api/job-notes/${jobId}/notes/${noteId}`
  ),
  deleteJobNotes: methodUrl(
    'delete',
    ({ jobId, noteId }: { jobId: number; noteId: number }) =>
      `${API_URL}/api/job-notes/${jobId}/notes/${noteId}`
  ),

  // candidate calendar
  candidate: {
    getEvent: methodUrl(
      'get',
      ({ id }: { id: number }) => `${API_URL}/api/candidate/calendar/events/${id}`
    ),
    getEventsList: methodUrl('get', `${API_URL}/api/candidate/calendar/events`),
    postEvent: methodUrl('post', `${API_URL}/api/candidate/calendar/events`),
    putEvent: methodUrl(
      'put',
      ({ id }: { id: number }) => `${API_URL}/api/candidate/calendar/events/${id}`
    ),
    deleteEvent: methodUrl(
      'delete',
      ({ id }: { id: number }) => `${API_URL}/api/candidate/calendar/events/${id}`
    )
  },

  // recruiter
  getHM: methodUrl('get', `${API_URL}/api/recruiter/profile/managers`),
  getJobsRecruiter: methodUrl('get', `${API_URL}/api/recruiter/jobs`),
  getJobsRecruiterForFilter: methodUrl('get', `${API_URL}/api/recruiter/jobs-for-filter`),
  getJobsRecruiterCount: methodUrl('get', `${API_URL}/api/recruiter/new-jobs/count`),
  getJobRecruiter: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/jobs/${id}`
  ),
  getRecruiterProfileR: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/recruiters/${id}`
  ),
  getManagerProfileR: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/managers/${id}`
  ),
  getAppliedJobsCandidate: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/candidates/${id}/apply/jobs`
  ),
  getRecruiterCandidateCurrent: methodUrl(
    'get',
    ({ candidateId }: { candidateId: number }) =>
      `${API_URL}/api/recruiter/candidates/${candidateId}/current`
  ),

  // candidate
  getCandidatesR: methodUrl('get', `${API_URL}/api/recruiter/candidates`),
  getCandidateProfileR: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/candidates/${id}`
  ),
  putApproveCandidate: methodUrl(
    'put',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/candidates/${id}/approve`
  ),
  getRecruiterCandidateJobs: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/candidates/${id}/jobs`
  ),
  getJobsForAssign: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/jobs-assign/${id}`
  ),
  assignCandidateToJobs: methodUrl(
    'post',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/jobs-assign/${id}`
  ),
  unassignedCandidateToJobs: methodUrl(
    'post',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/jobs-assign/${id}/cancel`
  ),
  getJobsSelfAssign: methodUrl(
    'get',
    ({ candidateId }: { candidateId: number }) =>
      `${API_URL}/api/recruiter/jobs-assign/${candidateId}/self_assigned`
  ),
  acceptsSelfAssignedCandidateJob: methodUrl(
    'post',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/jobs-assign/${id}/accept`
  ),
  declinesSelfAssignedCandidateJob: methodUrl(
    'post',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/jobs-assign/${id}/decline`
  ),

  // candidate job notes
  postCandidateJobNotes: methodUrl(
    'post',
    ({ candidateId, jobId }: { candidateId: number; jobId: number }) =>
      `${API_URL}/api/candidate-job-notes/${candidateId}/jobs/${jobId}`
  ),
  getCandidateJobNotes: methodUrl(
    'get',
    ({ candidateId, jobId }: { candidateId: number; jobId: number }) =>
      `${API_URL}/api/candidate-job-notes/${candidateId}/jobs/${jobId}`
  ),
  deleteCandidateJobNote: methodUrl(
    'delete',
    ({ noteId, candidateId, jobId }: { noteId: number; candidateId: number; jobId: number }) =>
      `${API_URL}/api/candidate-job-notes/${noteId}/candidates/${candidateId}/jobs/${jobId}`
  ),
  editCandidateJobNote: methodUrl(
    'put',
    ({ noteId, candidateId, jobId }: { noteId: number; candidateId: number; jobId: number }) =>
      `${API_URL}/api/candidate-job-notes/${noteId}/candidates/${candidateId}/jobs/${jobId}`
  ),

  // candidate dashboard
  candidateAssignedJobs: methodUrl('get', `${API_URL}/api/candidate-dashboard/assigned-jobs`),
  candidateAppliedJobs: methodUrl('get', `${API_URL}/api/candidate-dashboard/applied-jobs`),
  candidateFavoriteJobs: methodUrl('get', `${API_URL}/api/candidate-dashboard/favorite-jobs`),

  // candidate invite
  getCandidatesAcceptedInvites: methodUrl(
    'get',
    `${API_URL}/api/candidate-invites/accepted-invites`
  ),
  postCandidateInvites: methodUrl('post', `${API_URL}/api/candidate-invites/invite`),
  getPendingCandidates: methodUrl('get', `${API_URL}/api/candidate-invites/my-invites`),
  cancelCandidateInvite: methodUrl(
    'post',
    ({ inviteId }: { inviteId: number }) =>
      `${API_URL}/api/candidate-invites/cancel-invitation/${inviteId}`
  ),
  resendCandidateInvite: methodUrl(
    'post',
    ({ inviteId }: { inviteId: number }) =>
      `${API_URL}/api/candidate-invites/resend-invitation/${inviteId}`
  ),

  // points
  getPoints: methodUrl('get', `${API_URL}/api/balance/points-info`),
  receivedPoints: methodUrl('get', `${API_URL}/api/recruiter/profile/rewards`),
  rewardsView: methodUrl(
    'post',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/profile/rewards/${id}/view`
  ),
  redeem: methodUrl('post', `${API_URL}/api/balance/redeem`),

  // recruiter dashboard
  recruiterJobsInProgress: methodUrl('get', `${API_URL}/api/recruiter/dashboard/jobs-in-progress`),
  recruiterJobsCounters: methodUrl('get', `${API_URL}/api/recruiter/dashboard/jobs-counters`),
  recruiterClosedJobs: methodUrl('get', `${API_URL}/api/recruiter/dashboard/closed-jobs`),
  recruiterRecruiterSkills: methodUrl('get', `${API_URL}/api/recruiter/dashboard/skills`),
  recruiterClients: methodUrl('get', `${API_URL}/api/recruiter/dashboard/clients`),
  recruiterStatistics: methodUrl('get', `${API_URL}/api/recruiter/dashboard/statistics`),
  recruiterTopRecruiters: methodUrl('get', `${API_URL}/api/recruiter/dashboard/top-recruiters`),

  // recruiter calendar
  recruiter: {
    getEvent: methodUrl(
      'get',
      ({ id }: { id: number }) => `${API_URL}/api/recruiter/calendar/events/${id}`
    ),
    getEventsList: methodUrl(
      'get',
      ({ id }: { id: number }) => `${API_URL}/api/recruiter/calendar/manager/${id}/events`
    ),
    postEvent: methodUrl('post', `${API_URL}/api/recruiter/calendar/events`),
    putEvent: methodUrl(
      'put',
      ({ id }: { id: number }) => `${API_URL}/api/recruiter/calendar/events/${id}`
    ),
    deleteEvent: methodUrl(
      'delete',
      ({ id }: { id: number }) => `${API_URL}/api/recruiter/calendar/events/${id}`
    ),
    getCandidatesList: methodUrl('get', `${API_URL}/api/recruiter/candidates/list`)
  },

  // recruiter imported candidates
  getImportedCandidateProfile: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/imported-candidates/${id}`
  ),
  postImportedCandidates: methodUrl('post', `${API_URL}/api/recruiter/imported-candidates`),
  putImportedCandidates: methodUrl(
    'put',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/imported-candidates/${id}/update`
  ),
  postAvatarImportedCandidates: methodUrl(
    'post',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/imported-candidates/${id}/avatar`
  ),
  deleteResumeImportedCandidates: methodUrl(
    'delete',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/imported-candidates/${id}/resume/delete`
  ),
  parseResumeImportedCandidates: methodUrl(
    'post',
    `${API_URL}/api/recruiter/imported-candidates/parse-resume`
  ),
  parseResumeAndCreateImportedCandidates: methodUrl(
    'post',
    `${API_URL}/api/recruiter/imported-candidates/parse-resume-and-create`
  ),
  getParsedResumes: methodUrl('get', `${API_URL}/api/recruiter/parsed-resumes`),
  getParsedResume: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/recruiter/parsed-resumes/${id}`
  ),
  addParsedResumeToQueue: methodUrl('post', `${API_URL}/api/recruiter/parsed-resumes/add-to-queue`),
  removeParsedResumeFromQueue: methodUrl(
    'post',
    `${API_URL}/api/recruiter/parsed-resumes/cancel-parse`
  ),

  // notifications
  getNotifications: methodUrl('get', `${API_URL}/api/notifications`),
  getUnreadNotifications: methodUrl('get', `${API_URL}/api/notifications/unread`),
  postNotificationsAsViewed: methodUrl('post', `${API_URL}/api/notifications/view`),

  // users
  checkUserEmail: methodUrl('get', `${API_URL}/api/users/check-email`),
  superManagerRequest: methodUrl('post', `${API_URL}/api/manager/super-manager-request`),
  resendCollaborationRequest: methodUrl(
    'patch',
    ({ id }: { id: number }) => `${API_URL}/api/user/collaboration-requests/${id}/resend`
  ),

  // super manager
  getManagers: methodUrl('get', `${API_URL}/api/super-manager/managers`),
  getSMManagerProfile: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/super-manager/managers/${id}`
  ),
  getManagersSearch: methodUrl('get', `${API_URL}/api/super-manager/managers/search`),
  getCollaborationRequests: methodUrl('get', `${API_URL}/api/super-manager/collaboration-requests`),
  sendCollaborationRequest: methodUrl(
    'post',
    `${API_URL}/api/super-manager/managers/collaboration-requests/batch`
  ),
  declineCollaborationRequest: methodUrl(
    'delete',
    ({ id }: { id: number }) => `${API_URL}/api/super-manager/managers/${id}/collaboration-request`
  ),

  // super manager candidate
  getCandidateProfileSM: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/super-manager/candidates/${id}`
  ),
  // super manager jobs
  getSuperManagerJobs: methodUrl('get', `${API_URL}/api/super-manager/jobs`),
  getJobSuperManager: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/super-manager/jobs/${id}`
  ),

  // super manager clients
  getSuperManagerClients: methodUrl('get', `${API_URL}/api/super-manager/clients`),

  // super manager dashboard
  superManagerClientsCounters: methodUrl(
    'get',
    `${API_URL}/api/super-manager/dashboard/client-counters`
  ),
  superManagerJobsCounters: methodUrl(
    'get',
    `${API_URL}/api/super-manager/dashboard/managers/job-counters`
  ),
  superManagerStatistics: methodUrl(
    'get',
    `${API_URL}/api/super-manager/dashboard/managers/statistics`
  ),
  superManagerClosedJobs: methodUrl(
    'get',
    `${API_URL}/api/super-manager/dashboard/managers/closed-jobs`
  ),
  superManagerClientClosureRate: methodUrl(
    'get',
    `${API_URL}/api/super-manager/dashboard/clients-closure-rate`
  ),
  superManagerTopRecruiters: methodUrl(
    'get',
    ({ id }: { id: number }) =>
      `${API_URL}/api/super-manager/dashboard/managers/${id}/top-recruiters`
  ),
  superManagerTopManagers: methodUrl('get', `${API_URL}/api/super-manager/dashboard/managers/top`),

  // super manager candidate job notes
  postSMCandidateJobNotes: methodUrl(
    'post',
    ({ candidateId, jobId }: { candidateId: number; jobId: number }) =>
      `${API_URL}/api/super-manager/candidate-job-notes/${candidateId}/jobs/${jobId}`
  ),
  getSMCandidateJobNotes: methodUrl(
    'get',
    ({ candidateId, jobId }: { candidateId: number; jobId: number }) =>
      `${API_URL}/api/super-manager/candidate-job-notes/${candidateId}/jobs/${jobId}`
  ),
  deleteSMCandidateJobNote: methodUrl(
    'delete',
    ({ noteId, candidateId, jobId }: { noteId: number; candidateId: number; jobId: number }) =>
      `${API_URL}/api/super-manager/candidate-job-notes/${noteId}/candidates/${candidateId}/jobs/${jobId}`
  ),
  editSMCandidateJobNote: methodUrl(
    'put',
    ({ noteId, candidateId, jobId }: { noteId: number; candidateId: number; jobId: number }) =>
      `${API_URL}/api/super-manager/candidate-job-notes/${noteId}/candidates/${candidateId}/jobs/${jobId}`
  ),

  // super manager job notes
  getSMJobNotes: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/super-manager/job-notes/${id}`
  ),
  postSMJobNotes: methodUrl(
    'post',
    ({ id }: { id: number }) => `${API_URL}/api/super-manager/job-notes/${id}`
  ),
  putSMJobNotes: methodUrl(
    'put',
    ({ jobId, noteId }: { jobId: number; noteId: number }) =>
      `${API_URL}/api/super-manager/job-notes/${jobId}/notes/${noteId}`
  ),
  deleteSMJobNotes: methodUrl(
    'delete',
    ({ jobId, noteId }: { jobId: number; noteId: number }) =>
      `${API_URL}/api/super-manager/job-notes/${jobId}/notes/${noteId}`
  ),
  // recruiters
  getRecruiterSkills: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/recruiters/${id}/skills`
  ),
  getRecruiterJobs: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/recruiters/${id}/jobs`
  ),
  getRecruiterCandidates: methodUrl(
    'get',
    ({ id }: { id: number }) => `${API_URL}/api/recruiters/${id}/candidates`
  ),

  // jobs
  updateJobRecruiters: methodUrl(
    'patch',
    ({ id }: { id: number }) => `${API_URL}/api/jobs/${id}/recruiters`
  )
}
